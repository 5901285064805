import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import HeadHelmet from '../components/head';
import ContactDiv from '../components/contactUs';
import Layout from '../components/layout';
import { Inner, GridRow, GridColumn } from '../components/ui-elements/Grid';
import { StyledBackground } from '../components/ui-elements/backgroundImage';
import NewsCards from '../components/pages/news/newsCards';
import Pagination from '../components/pagination';
import Carousel from '../components/ui-elements/carousel';
import initIContactScript from '../utils/initIContactScript';

const AllResources = ({ data, pageContext, location }) => {
  const { strapiSsResources, allStrapiSsResourceGuides } = data;
  const { title, subscribe_description, article_section_title, hero_image } = strapiSsResources;
  const { limit, numPagesGuides, numOfGuides, currentPage } = pageContext;

  const featuredItems = allStrapiSsResourceGuides.edges.filter(
    (item) => item.node.featured_guide === true,
  );

  useEffect(() => {
    initIContactScript();
  }, []);

  return (
    <>
      <HeadHelmet data={strapiSsResources.MetaData} />
      <Layout>
        <StyledBackground
          img={ hero_image?.childImageSharp.fluid }
          title={ title }
          titleWidth="100%"
        />
        <Inner>

          <Carousel featuredItems={featuredItems} />

          <div>
            <h3>{article_section_title}</h3>
          </div>

          <NewsCards
            queryData={allStrapiSsResourceGuides}
            location={location.pathname}
            hideViewAllButton
          />
          <Pagination
            currentPage={currentPage}
            numPages={numPagesGuides}
            numOfPosts={numOfGuides}
            totalItemCount={limit}
          />
        </Inner>
        <div style={{ background: '#F7F9FA', padding: '1rem 0' }}>
          <div className={'SharedEl__textContent--left'}>
            <GridRow>
              <GridColumn>
                <div>
                  <div>
                    <form
                      className={'Contact__form'}
                      method="POST"
                      name="contact"
                      data-netlify-honeypot="bot-field"
                      data-netlify="true"
                      id="ic_signupform"
                      captcha-key="6LeCZCcUAAAAALhxcQ5fN80W6Wa2K3GqRQK6WRjA"
                      captcha-theme="light"
                      new-captcha="true"
                      action="https://app.icontact.com/icp/core/mycontacts/signup/designer/form/?id=658&cid=1791092&lid=33433"
                    >
                      <input type="hidden" name="form-name" value="contact" />
                      <input
                        type="hidden"
                        name="subject"
                        value="New form submission from stanfordsonoma.com"
                      />
                      <div
                        data-validation-type="1"
                        data-label="First Name">
                        <label>
                          First Name*
                        <input type="text" name="data[fname]" required />
                        </label>{' '}
                      </div>
                      <div
                        data-validation-type="1"
                        data-label="Last Name"
                      >
                        <label>
                          Last Name*
                        <input type="text" name="data[lname]" required />
                        </label>{' '}
                      </div>
                      <div
                        data-validation-type="1"
                        data-label="Company Name"
                      >
                        <label>
                          Company Name
                        <input type="text" name="data[business]" />
                        </label>{' '}
                      </div>
                      <div
                        data-validation-type="1"
                        data-label="Email"
                      >
                        <label>
                          Email* <br />
                          <input type="email" name="data[email]" required />
                        </label>{' '}
                      </div>
                      <div
                        data-validation-type="1"
                        data-label="Phone Number"
                      >
                        <label>
                          Phone Number*
                        <input type="number" name="data[phone]" />
                        </label>{' '}
                      </div>
                      <div
                        dataname="listGroups"
                        data-validation-type="1"
                        data-label="Lists"
                        hidden
                      >
                        <label className="checkbox">
                          <input
                            type="checkbox" alt=""
                            name="data[listGroups][]"
                            value="93324"
                            checked
                          />Newsletter Sign Ups
                        </label>
                      </div>
                      <div className="submit-container">
                      <button className={'Services__blueButton'} type="submit" >
                        SUBSCRIBE
                      </button>
                      </div>
                      <img src="//app.icontact.com/icp/core/signup/tracking.gif?id=658&cid=1791092&lid=33433" alt={''} />
                    </form>
                  </div>
                </div>
              </GridColumn>
              <GridColumn>
                <ReactMarkdown>{subscribe_description}</ReactMarkdown>
              </GridColumn>
            </GridRow>
          </div>
        </div>
        <ContactDiv />
      </Layout>
    </>
  );
};

export default AllResources;

export const pageQuery = graphql`
  query Guides($limit: Int!, $skip: Int!) {
    strapiSsResources {
      id
      MetaData {
        id
        title
        metaKeywords
        metaDescription
      }
      title
      hero_image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      subscribe_description
      article_section_title
    }
    allStrapiSsResourceGuides(
      sort: { fields: [published], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug
          featured_guide
          featured_guide_short_description
          published
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
