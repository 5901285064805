import styled from 'styled-components';
import theme from '../../theme';

const PaginationStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${theme.spacingXXL};

  .pagination {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${theme.fontSizeS};
    color: #707077;
    margin-bottom: 0;
    cursor: pointer;
    padding: 0;

    &__page {
      margin-left: 17px;
      margin-bottom: ${theme.spacingS};

      &:first-child {
        margin-left: 0;
      }

      &.${theme.classNames.active} {
        font-size: ${theme.fontSizeBody};
        font-weight: ${theme.fontWeightBold};
        color: #131313;
        border-bottom: 2px solid transparent;
        border-image: linear-gradient(to right, #ffda00, #ff9900);
        border-image-slice: 1;
      }

      &:hover {
        font-size: ${theme.fontSizeBody};
        font-weight: ${theme.fontWeightBold};
        color: #131313;
        border-bottom: 2px solid transparent;
        border-image: linear-gradient(to right, #ffda00, #ff9900);
        border-image-slice: 1;
      }
      a {
        text-decoration: none;
      }
      a:visited {
        color: #131313;
      }
    }

    &__next-page {
      height: 50px;
      width: 50px;
      cursor: pointer;
      margin-left: 44px;
      margin-bottom: ${theme.spacingS};

      img {
        height: 100%;
        width: 100%;
      }
    }

    &__previous-page {
      height: 50px;
      width: 50px;
      cursor: pointer;
      margin-right: 44px;
      margin-bottom: ${theme.spacingS};

      img {
        height: 100%;
        width: 100%;
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: ${theme.breakpoints.mobile}) {
    flex-wrap: wrap;
    margin-bottom: 22px;

    .pagination {
      flex-basis: 100%;

      &__page {
        margin-left: 12px;
      }

      &__next-page {
        margin-left: ${theme.spacingS};
        margin-top: 5px;
        order: 3;
      }

      &__previous-page {
        margin-right: 0;
        margin-top: 5px;
        order: 2;
      }
    }
  }
`;

export default PaginationStyle;
