import React, { useState } from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import { GridColumn, GridRow } from './Grid';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme from '../../theme';

const Image = styled.div`
  ${(props) =>
    props.image &&
    `
        background-image: url(${props.image});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
        border-radius: 8px;
      `}
`;

const FeaturedCard = styled.div`
  margin-top: 4rem;
  @media (min-width: 0) {
   // min-height: 40vh;
    box-shadow: 2px 4px 12px #04192f3d;
    background: #ffffff;
    border-radius: 8px;
    text-align: left;
    padding: 0.5rem;
    h3 {
      margin: 1rem 0;
      text-align: left;
    }
    .learnMore {
      display: inline-block;
      background: var(--base-color);
      box-shadow: 0 2px 10px #002f6c33;
      border-radius: 22px;
      letter-spacing: 0;
      color: #ffffff;
      text-decoration: none;
      padding: 0.75rem 1.25rem;
    }
    .textDiv {
      display: flex;
      flex-direction: column;
      margin-left: 0rem;
      p {
        min-height: 10vh;
      }
      a {
        align-self: flex-start;
      }
    }
    .featured {
      display: inline-block;
      float: right;
      font-size: 14px;
      font-weight: bold;
      margin: 0.5rem;
      background: var(--theme-background-gradient);
    }
  }
  @media (min-width: 960px) {
    padding: 1rem 1rem;
    .textDiv {
      margin-left: 3rem;
    }
    .featured {
      margin: 0;
    }
  }

  .specific-store__list {
    &__item {
      box-shadow: ${theme.boxShadowBase};
      border: ${theme.borderBase};
      border-radius: ${theme.borderRadiusXL};
      overflow: hidden;
      margin: ${theme.spacingL} 0;

      &__image {
        height: 180px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .specific-store__list {
      &__item {
        &__actions {
          text-align: center;

          a {
            margin: 5px;
            width: 100%;
          }
        }
      }
    }
  }

  @media only screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
    .specific-store__list {
      &__item {
        display: flex;

        &__image {
          width: 50%;
          height: auto;
        }
      }
    }
  }
  ${(props) => props.customCSS && props.customCSS}
`;

const OuterWrapper = styled.div`
  @media(min-width: 0) {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 90%;
  }
  @media(min-width: 960px) {
    width: 80%;
  }
`;

const SliderWrapper = styled.div`
  margin-bottom: 5rem;
  .right-arrow {
    @media (min-width: 0) {
      position: absolute;
      bottom: -75px;
      right: 0;
      font-size: 3rem;
      color: #000;
      cursor: pointer;
      user-select: none;
    }
    @media (min-width: 960px) {
      top: 50%;
      right: -125px;
      bottom: -90px;
    }
  }

  .left-arrow {
    @media (min-width: 0) {
      position: absolute;
      bottom: -75px;
      left: 0;
      font-size: 3rem;
      color: #000;
      cursor: pointer;
      user-select: none;
    }
    @media (min-width: 960px) {
      top: 50%;
      left: -125px;
      bottom: -90px;
    }
  }

  .slide {
    opacity: 0;
    transition: 1s ease-in;
  }

  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
`;

const Carousel = ({ featuredItems }) => {
  const [current, setCurrent] = useState(0);
  const length = featuredItems.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(featuredItems) || featuredItems.length <= 0) {
    return null;
  }

  return (
    <OuterWrapper>
      <SliderWrapper>
        <section className="slider">
          <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
          <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
          {featuredItems.map((slide, index) => {
            const { image, featured_guide_short_description, slug, title, published } = slide.node;

            return (
              <div className={index === current ? 'slide active' : 'slide'} key={index}>
                {index === current && (
                  <>
                    <FeaturedCard>
                      <GridRow>
                        <GridColumn>
                          <div className={'learnMore featured'}>FEATURED</div>
                        </GridColumn>
                      </GridRow>
                      <GridRow>
                        <Image
                          image={image.childImageSharp.fluid.src}
                          className="specific-store__list__item__image"
                        />
                        <GridColumn>
                          <div className={'textDiv'}>
                            <h3>{title}</h3>
                            <p>{featured_guide_short_description}</p>
                            <Link to={`/resources/all-resources/${slug}`} className={'learnMore'}>
                              Learn More
                            </Link>
                          </div>
                        </GridColumn>
                      </GridRow>
                    </FeaturedCard>
                  </>
                )}
              </div>
            );
          })}
        </section>
      </SliderWrapper>
    </OuterWrapper>
  );
};

export default Carousel;
