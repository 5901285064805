import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import StyledPagination from './style';
import ArrowIcon from '../../../static/images/arrow_r.svg';
import theme from '../../theme';

const Pagination = ({ currentPage, numPages, numOfPosts, totalItemCount }) => {
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(numPages);

  const totalPages = [...Array(Math.ceil(numOfPosts / totalItemCount)).keys()];

  useEffect(() => {
    if (totalPages.length <= 10) {
      setStartPage(1);
      setEndPage(totalPages.length);
    } else if (currentPage <= 6) {
      setStartPage(1);
      setEndPage(10);
    } else if (currentPage + 4 >= totalPages.length) {
      setStartPage(totalPages.length - 9);
      setEndPage(totalPages.length);
    } else {
      setStartPage(currentPage - 5);
      setEndPage(currentPage + 4);
    }
  }, [currentPage, numPages]);

  const handlePageChange = (page) => {
    console.log(totalPages.length, 'totalPages 1');
    console.log(page, 'PAGE 1');

    if (page > totalPages.length) {
      navigate(`/our-company/news/page-1`);
    } else {
      navigate(`/our-company/news/page-${page}`);
    }
  };

  return (
    <StyledPagination>
      {currentPage > 1 && (
        <div
          role="button"
          tabIndex="0"
          className="pagination__previous-page"
          onClick={() => handlePageChange(currentPage - 1)}
          onKeyPress={() => handlePageChange(currentPage - 1)}
        >
          <img src={ArrowIcon} alt="previous-arrow" />
        </div>
      )}
      <ul className="pagination">
        {totalPages.slice(startPage - 1, endPage).map((page) => {
          return (
            <li
              className={`pagination__page ${
                page + 1 === currentPage ? theme.classNames.active : ''
              }`}
              onClick={() => handlePageChange(page + 1)}
              onKeyPress={() => handlePageChange(page + 1)}
              id={`pagination__page-${page + 1}`}
              key={page}
            >
              <Link to={`/our-company/news/page-${page + 1}`}>
                {page + 1 < 10 ? `0${page + 1}` : page + 1}
              </Link>
            </li>
          );
        })}
      </ul>
      {totalPages.length > 1 && (
        <div
          role="button"
          tabIndex="0"
          className="pagination__next-page"
          onClick={() => handlePageChange(currentPage + 1)}
          onKeyPress={() => handlePageChange(currentPage + 1)}
        >
          <img src={ArrowIcon} alt="next-arrow" />
        </div>
      )}
    </StyledPagination>
  );
};

export default Pagination;
